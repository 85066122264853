import PropTypes from "prop-types";
import Image from "next/image";

import { Container, IconContainer, Title } from "./PageTitle.styled";

function PageTitle({ children, type, pageIcon, className, ...rest }) {
    return (
        <Container>
            <Title
                className={className}
                type={type}
                pageIcon={pageIcon}
                {...rest}
            >
                {children}
            </Title>
            {pageIcon && (
                <IconContainer type={type}>
                    <Image
                        src={pageIcon}
                        alt={`${children.toString()} icon`}
                        width={type === "small" ? 64 : 110}
                        height={type === "small" ? 64 : 110}
                        priority
                        unoptimized
                    />
                </IconContainer>
            )}
        </Container>
    );
}

PageTitle.propTypes = {
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        .isRequired,
    pageIcon: PropTypes.string,
    type: PropTypes.oneOf(["small", "large"]),
    className: PropTypes.string
};

PageTitle.defaultProps = {
    pageIcon: undefined,
    type: "small",
    className: ""
};

export default PageTitle;
