import PropTypes from "prop-types";

export default PropTypes.shape({
    id: PropTypes.string.isRequired,
    slug: PropTypes.string.isRequired,
    logo: PropTypes.shape({
        alt: PropTypes.string.isRequired,
        url: PropTypes.string.isRequired
    }),
    address: PropTypes.shape({
        provinceCode: PropTypes.string,
        city: PropTypes.string
    })
});
