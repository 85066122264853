import styled, { css } from "styled-components";

import media from "constants/media";

export const Container = styled.div`
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    width: 100%;

    ${media.mdUp} {
        align-items: flex-start;
        padding-top: 0;
    }
`;

const smallIconStyling = css`
    width: 34px;
    height: 34px;
    margin: 0 0 5px;

    ${media.mdUp} {
        width: 64px;
        height: 64px;
        margin-bottom: 0;
    }
`;

const largeIconStyling = css`
    width: 70px;
    height: 70px;

    ${media.mdUp} {
        width: 100px;
        height: 90px;
        margin-top: -100px;
    }

    ${media.lgUp} {
        width: 150px;
        height: 110px;
        margin-top: -110px;
    }
`;

export const IconContainer = styled.div`
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;

    ${media.mdUp} {
        justify-content: flex-start;
    }

    ${({ type }) => (type === "small" ? smallIconStyling : largeIconStyling)}
`;

const smallTitleStyling = css`
    margin: 0 0 10px;

    ${media.mdUp} {
        max-width: initial;
        margin: 0 0 20px;

        font-size: 50px;
        line-height: 65px;
    }
`;

const largeTitleStyling = css`
    margin: 70px 0 0;

    ${media.lgUp} {
        max-width: 523px;
        margin: 0 0 35px;

        font-size: 70px;
        line-height: 85px;
    }
`;

export const Title = styled.h1`
    position: relative;

    max-width: 483px;

    font-weight: var(--font-weight-normal);
    font-size: 30px;
    font-family: var(--font-family-secondary);
    line-height: 40px;
    text-align: center;

    ${media.mdUp} {
        font-size: 50px;
        text-align: left;
    }

    ${({ type }) => (type === "small" ? smallTitleStyling : largeTitleStyling)}
`;
