import PropTypes from "prop-types";
import Link from "next/link";
import { useMemo, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useQuery } from "@apollo/client/react/hooks";

import MerchantLogoPropTypes from "types/MerchantLogo.propTypes";
import { useMediaQueryContext } from "helpers/hooks/useMediaQueryContext";
import { filterMerchants, generateMerchantSlug } from "helpers/merchant";
import { GetMerchantLogos } from "lib/api/cc/queries/merchants.gql";

import {
    Container,
    LogoGrid,
    Title,
    LocationsButton,
    Anchor,
    LogoSkeleton,
    LogoWrapper,
    Logo,
    Slider
} from "./MerchantLogos.styled";

function renderLogos(merchants, skeletons) {
    if (!merchants || merchants.length === 0) {
        return skeletons;
    }

    return merchants
        .filter(({ logo, __typename }) => __typename === "Sauna" && logo?.url)
        .map(({ id, address, slug, logo }) => (
            <Link
                key={id}
                href={generateMerchantSlug(
                    address?.provinceCode,
                    address?.city,
                    slug
                )}
                passHref
                prefetch={false}
            >
                <Anchor>
                    <LogoWrapper>
                        <Logo
                            alt={logo.alt}
                            src={logo.url}
                            layout="fill"
                            objectFit="contain"
                            quality={75}
                        />
                    </LogoWrapper>
                </Anchor>
            </Link>
        ));
}

function MerchantLogos({
    title,
    merchants,
    link,
    variant,
    amountToShow,
    className
}) {
    const [merchantLogos, setMerchantLogos] = useState(merchants);
    const { mobileView } = useMediaQueryContext();
    const { ref: blockRef, inView: blockInView } = useInView({
        threshold: 0.2,
        triggerOnce: true,
        initialInView: false
    });
    const { ref: sliderRef, inView: sliderInView } = useInView({
        triggerOnce: true,
        initialInView: false
    });

    const skeletons = useMemo(
        () =>
            Array(amountToShow)
                .fill()
                .map((item, index) => (
                    <LogoSkeleton key={`logo-skeleton-${index}`} />
                )),
        [amountToShow]
    );

    const logos = useMemo(() => {
        if (merchantLogos?.length > 0) {
            return renderLogos(merchantLogos);
        }

        return skeletons;
    }, [merchantLogos, skeletons]);

    useQuery(GetMerchantLogos, {
        variables: {
            pageSize: amountToShow
        },
        skip:
            !blockInView ||
            merchants.length > 0 ||
            !process.env.featureFlags.cmsData,
        onCompleted: ({ merchants: results }) => {
            const filteredMerchants = filterMerchants(results, true);

            if (filteredMerchants?.edges?.length > 0) {
                setMerchantLogos(filteredMerchants.edges);
            }
        }
    });

    return (
        <Container className={className} forwardedAs="section">
            <LogoGrid ref={blockRef} variant={variant}>
                <Title>{title}</Title>
                {variant === "grid" && (
                    <LocationsButton
                        element="a"
                        variant="outlined"
                        href={link?.url}
                        passHref
                        prefetch={false}
                    >
                        {link?.label}
                    </LocationsButton>
                )}
                {mobileView ? (
                    <Slider ref={sliderRef} inView={sliderInView}>
                        {logos}
                    </Slider>
                ) : (
                    logos
                )}
            </LogoGrid>
        </Container>
    );
}

MerchantLogos.propTypes = {
    title: PropTypes.string,
    merchants: PropTypes.arrayOf(MerchantLogoPropTypes),
    link: PropTypes.shape({
        url: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired
    }),
    amountToShow: PropTypes.number,
    variant: PropTypes.oneOf(["grid", "row"]),
    className: PropTypes.string
};

MerchantLogos.defaultProps = {
    title: "Te besteden bij de 210+ mooiste sauna’s, thermen & wellness resorts van Nederland",
    merchants: [],
    link: {
        url: "/resorts/",
        label: "Alle locaties bekijken"
    },
    variant: "grid",
    amountToShow: 17,
    className: ""
};

export default MerchantLogos;
