import provinces from "constants/provinces";

import { generateSlug, isNotEmpty } from "./utils";

export function generateMerchantSlug(province, city, slug) {
    if (!province || !city) {
        return `/resorts/${slug}/`;
    }

    const provinceSlug = generateSlug(province);
    const citySlug = generateSlug(city);

    return `/resorts/${provinceSlug}/${citySlug}/${slug}/`;
}

export function buildMerchantsFilterQuery(params) {
    const { q: query, private: isPrivateMerchants, city, province } = params;
    const provinceCode = province?.toUpperCase();
    const variables = {};
    const filter = {};

    if (provinceCode) {
        variables.slug = provinceCode;
    }

    if (city) {
        variables.slug = city;
    }

    if (query && !city && !provinceCode) {
        filter.province = { like: query };
        filter.city = { like: query };
        filter.title = { like: query };
    }

    if (isPrivateMerchants) {
        filter.private = Boolean(isPrivateMerchants);
    }

    return { ...variables, filter };
}

export function filterMerchants(merchants, onlyValidAddress = false) {
    let edges = [];

    if (Array.isArray(merchants?.edges)) {
        edges = merchants.edges.filter(merchant => {
            if (merchant.__typename !== "Sauna") {
                return false;
            }

            if (onlyValidAddress) {
                const { provinceCode, city } = merchant.address;
                return isNotEmpty(provinceCode) && isNotEmpty(city);
            }

            return true;
        });
    }

    return { ...merchants, edges };
}

export function getProvinceName(name) {
    return provinces.find(item => item.id === name)?.label || name;
}
