import styled, { css } from "styled-components";
import Image from "next/image";

import media from "constants/media";
import { hideScrollbar } from "theme/mixins";

import { skeleton } from "components/ui/skeleton/Skeleton";
import Button from "components/ui/button/Button";
import BaseContainer from "components/ui/container/Container";

export const Container = styled(BaseContainer)`
    margin-bottom: 50px;
    padding: 0;

    ${media.mdUp} {
        padding: 0 10px;
    }

    ${media.lgUp} {
        margin-bottom: 100px;
    }
`;

export const Title = styled.h2`
    max-width: 300px;
    margin: 0 0 20px;

    color: var(--color-gray-300);
    font-size: 16px;
    line-height: 20px;
    text-align: center;

    ${media.mdUp} {
        margin: 0;
    }
`;

export const LocationsButton = styled(Button)`
    ${media.mdUp} {
        grid-row: 2;
        grid-column: 4 / span 3;
    }
`;

export const LogoWrapper = styled.div`
    position: relative;

    width: 45px;
    aspect-ratio: 1 / 1;

    ${media.mdUp} {
        grid-row: auto;
        grid-column: auto / span 1;
        width: 50px;
    }

    ${media.lgUp} {
        width: 75px;
    }
`;

export const Logo = styled(Image)`
    opacity: 0.5;
    filter: grayscale(100%);

    transition: all var(--animation-duration-default) ease;
`;

export const Anchor = styled.a`
    scroll-snap-align: start;
    scroll-margin: 30px;

    @media (any-hover: hover) {
        :hover ${Logo} {
            opacity: 1;
            filter: grayscale(0%);
        }
    }
`;

export const LogoSkeleton = styled.div`
    ${skeleton()}

    flex: 1 0 auto;
    width: 45px;
    height: 45px;
    aspect-ratio: 1 / 1;

    scroll-snap-align: start;
    scroll-margin: 30px;

    ${media.mdUp} {
        grid-row: auto;
        grid-column: auto / span 1;
        width: 50px;
    }

    ${media.lgUp} {
        width: 75px;
        height: 75px;
    }
`;

export const Slider = styled.div`
    display: flex;
    flex-direction: row;
    gap: 0 30px;
    align-items: center;
    width: 100vw;
    height: 45px;
    margin: 20px 0 0;
    padding: 0 20px;
    overflow-x: scroll;

    transform: translateX(${({ inView }) => (inView ? 0 : 50)}%);
    scroll-snap-type: x mandatory;
    scroll-padding: 50%;

    transition: transform var(--animation-duration-long) ease;

    ${hideScrollbar}

    ${media.mobileOnly}
`;

const gridView = css`
    ${media.mdUp} {
        display: grid;
        grid-gap: 20px;
        grid-template: auto / repeat(9, 1fr);

        ${Title} {
            grid-row: 1;
            grid-column: 2 / span 7;
            max-width: 610px;

            font-size: 25px;
            line-height: 35px;
        }

        padding: 20px;
    }

    ${media.xlUp} {
        grid-gap: 20px 40px;
        padding: 40px;
    }
`;

const rowView = css`
    ${media.mdUp} {
        display: flex;
        flex-flow: row wrap;
        gap: 20px;
        justify-content: space-between;
        padding: 20px 40px;

        ${Title} {
            width: 100%;
            max-width: unset;

            font-size: var(--font-size-body);
            line-height: var(--line-height-body);
        }
    }
`;

export const LogoGrid = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    width: 100%;
    padding: 30px 10px;

    background-color: var(--color-white);

    ${({ variant }) => variant === "grid" && gridView}
    ${({ variant }) => variant === "row" && rowView}

    ${media.mdUp} {
        border-radius: var(--border-radius-medium);
    }
`;
